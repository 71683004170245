/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
import PageHeader from '@/components/PageHeader.vue'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import searchView from '@/components/searchView'
import Steps from '@/components/steps'
import Adsearch from '@/components/Adsearch'
import {
  regionData,
  CodeToText,
  TextToCode
} from 'element-china-area-data'
import $api from '@/request/productApis.js'
import Uploads from '@/components/uploads'
const Index = {
  name: 'Merchants1',
  components: {
    PageHeader,
    Steps,
    Adsearch,
    Uploads,
    ElImageViewer
  },
  mixins: [tableMixin, root],
  directives: {
    'el-select-loadmore': {
      inserted(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      checkListisSHOW: false,
      drugColumnWidth: '',
      isAppLa: false,
      showViewer: false,
      btnLoadiung1: false,
      MaterialFileList: [],
      showForm: false,
      dialogTableVisible1: false,
      imgsUrl: this.b2bstaticUrl,
      adSearchForm: [{
        name: '申请日期',
        date: true,
        value: 'ApplyDate'
      },
      {
        name: '企业名称',
        value: 'CompanyName'
      },
      {
        name: '企业简称',
        value: 'CompanyAbbreviation'
      },
      {
        name: '信用代码',
        value: 'CreditCode'
      },
      {
        name: '联系人',
        value: 'ContactName'
      },
      {
        name: '企业编号',
        value: 'CompanyNo'
      },
      {
        name: '被授权人',
        value: 'AuthorizedPerson'
      },
      {
        name: '客户分类',
        check: true,
        checkBox: [{
          name: '客户'
        }, {
          name: '供应商'
        }],
        value: 'CompanyType'
      },
      {
        name: '申请部门',
        value: 'ApplyDepartment'
      },
      {
        name: '申请人',
        value: 'ApplyUser'
      },
      {
        name: '当前状态',
        value: 'Status'
      },
      {
        name: '回款账期',
        value: 'PaymentCollectionDays'
      },
      {
        name: '付款方式',
        value: 'PayType'
      },
      {
        name: '开票税率',
        value: 'TaxRate'
      },
      {
        name: '详细地址',
        value: 'Street'
      },
      {
        name: '备注',
        value: 'Remark'
      }
      ],
      formSearch: {
        ApplyDate: '',
        CompanyName: '',
        CompanyAbbreviation: '',
        CreditCode: '',
        ContactName: '',
        CompanyNo: '',
        AuthorizedPerson: '',
        CompanyType: [],
        ApplyUser: '',
        ApplyDepartment: '',
        Status: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        PayType: '',
        Street: '',
        Remark: ''
      },
      isShow: false,
      isMore: false,
      checkList: [],
      options1: regionData,
      selectedOptions: [],
      selectedOptions1: [],
      input2: '',
      keyWords: '',
      value: '',
      // 行高改变的一些字段
      targetTd: null,
      coltargetTd: null,
      resizeable: false,
      mousedown: false,
      types: [],
      options: [],
      dialogTableVisible: false,
      formInline: {
        user: '',
        region: ''
      },
      userInfo: {},
      value1: '',
      active: 0,
      btnLoadiung: false,
      rules: {
        // ApplyDate: [
        //   { required: true, message: '请选择申请日期', trigger: 'blur' }
        // ],
        CompanyName: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }],
        CompanyType: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.CompanyType === 0) {
              callback(new Error('请选择企业类型'))
            }
            callback()
          },
          trigger: 'blur'
        }],
        CreditCode: [{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.form.CreditCode) {
              callback(new Error('请输入社会信用统一代码'))
            }
            if (this.form.CreditCode) {
              if (this.form.CreditCode.length !== 18) callback(new Error('请输入18位社会信用统一代码'))
              // const regNumber = /\d+/ // 验证0-9的任意数字最少出现1次。
              // const regString = /[A-Z]+/ // 验证大小写26个字母任意字母最少出现1次。
              const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
              // 验证第一个字符串
              if (reg.test(this.form.CreditCode)) {
                console.log('str1：验证成功')
              } else {
                callback(new Error('请输入正确的社会信用统一代码'))
              }
            }
            callback()
          },
          trigger: 'blur'
        }],
        // PaymentCollectionDays: [
        //   { required: true, message: '请输入回款账期', trigger: 'blur' }
        // ],
        // ApplyDepartmentID: [
        //   { required: true, message: '请输入申请部门', trigger: 'blur' }
        // ],
        // TaxRate: [
        //   { required: true, message: '请输入开票税率', trigger: 'blur' }
        // ],
        Province: [{
          required: true,
          validator: (rule, value, callback) => {
            if (this.form.Province === '' || this.form.City === '' || this.form.Area === '' || this.form.Street === '') {
              callback(new Error('请输入详细地址'))
            }
            callback()
          },
          trigger: 'blur'
        }]
      },
      col: [{
        label: '审核状态',
        prop: 'Status'
      },
      {
        label: '企业名称',
        prop: 'CompanyName'
      },
      {
        label: '客户分类',
        prop: 'CompanyType'
      },
      {
        label: '企业编号',
        prop: 'CompanyNo'
      },
      {
        label: '社会统一信用代码',
        prop: 'CreditCode'
      },
      {
        label: '联系人',
        prop: 'ContactName'
      },
      {
        label: '联系电话',
        prop: 'ContactTel'
      },
      {
        label: '申请部门',
        prop: 'ApplyDepartment'
      },
      {
        label: '申请人',
        prop: 'ApplyUser'
      },
      {
        label: '申请日期',
        prop: 'ApplyDateString'
      },
      {
        label: '填表人',
        prop: 'FillFormUser'
      },
      {
        label: '传真',
        prop: 'Fax'
      },
      {
        label: '邮箱',
        prop: 'Email'
      },
      {
        label: '企业简称',
        prop: 'CompanyAbbreviation'
      },
      {
        label: '企业法人',
        prop: 'CorporationName'
      },
      {
        label: '法人电话',
        prop: 'CorporationTel'
      },
      {
        label: '被授权人',
        prop: 'AuthorizedPerson'
      },
      {
        label: '地址',
        prop: 'Street'
      },
      {
        label: '开户银行',
        prop: 'Bank'
      },
      {
        label: '开户支行',
        prop: 'BranchBank'
      },
      {
        label: '付款方式',
        prop: 'PayType'
      },
      {
        label: '回款账期',
        prop: 'PaymentCollectionDays'
      },
      {
        label: '开票税率',
        prop: 'TaxRate'
      },
      {
        label: '备注',
        prop: 'Remark'
      },
      {
        label: '操作',
        prop: 'Handle'
      }
      ],
      tableData1: [],
      form: {
        IDX: 0,
        ApplyDate: this.$minCommon.formatDate(new Date(), 'yyyy/MM/dd'),
        ApplyDepartmentID: '',
        ApplyUserID: '',
        CompanyType: 1,
        CompanyName: '',
        CreditCode: '',
        CompanyAbbreviation: '',
        CompanyNo: '',
        ContactName: '',
        ContactTel: '',
        Fax: '',
        Email: '',
        CorporationName: '',
        CorporationTel: '',
        AuthorizedPerson: '',
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Bank: '',
        BranchBankProvince: '',
        BranchBankCity: '',
        BranchBankArea: '',
        BranchBank: '',
        PayType: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        Remark: '',
        CompanyCertList: [] // 暂存的证照
      },
      searchWords: {
        PageIndex: this.page,
        PageSize: this.pageSize,
        KeyWords: '',
        ApplyDateBegin: '',
        ApplyDateEnd: '',
        ApplyUser: '',
        ApplyDepartment: '',
        CompanyAbbreviation: '',
        AuthorizedPerson: '',
        PaymentCollectionDays: '',
        PayType: '',
        TaxRate: '',
        Street: '',
        Remark: '',
        ContactTel: '',
        ContactName: '',
        CreditCode: '',
        CompanyNo: '',
        CompanyType: 0,
        CompanyName: '',
        Status: 0
      },
      info: {},
      info_temp: {},
      selects: [],
      selectsStr: '',
      IDX: '',
      firstCampCompanyID: '',
      ImgsListSeclt: [],
      ImgsListSecltStr: '',
      PermissionGroup: [],
      ApplyUserGroup: [],
      ApplyUserPage: 1,
      ApplyUserPageSize: 15,
      // 弹窗 start //
      showElement: false,
      process_list: [{
        name: '审批编号',
        value: 'ApprovalCode',
        icon: 'el-icon-bell'
      }, {
        name: '流程状态',
        value: 'StatusString',
        icon: 'el-icon-position'
      }, {
        name: '审批类型',
        value: 'ApprovalTypeString',
        icon: 'el-icon-mobile'
      }, {
        name: '申请部门',
        value: 'ApprovalApplyDepartment',
        icon: 'el-icon-coordinate'
      }, {
        name: '申请人',
        value: 'ApprovalApplyUser',
        icon: 'el-icon-user'
      }, {
        name: '发起时间',
        value: 'BeginTimeString',
        icon: 'el-icon-watch'
      }],
      RecordInfo: {
        RecordInfo: {}
      },
      formJson: [],
      dataJson: {},
      view_item: {},
      RecordList: [],
      RecordEditList: [],
      UserList: [],
      ChatList: [],
      input_value: '',
      disabled: true,
      chat_value_length: 0,
      activeName_business: 'first',
      sendMsgForm: {
        RecordID: '',
        MessageType: '',
        FileExtName: '',
        Message: '',
        AtUserID: '',
        Reason: ''
      },
      content_view_chat: null,
      selectsArr: [],
      root_name: 'qysy',
      root_msg: '企业首营',
      // 弹窗 end  //
      isStaticPic: false // 是否静态展示已上传证照
    }
  },
  watch: {
    // 留言内容监听
    input_value(a) {
      this.sendMsgForm.Message = a
      this.chat_value_length = a.length
    },
    checkList(a) {
      console.log(a)
      if (a.indexOf('客户') !== -1 && a.indexOf('供应商') !== -1) {
        this.form.CompanyType = 4
      }
      if (a.indexOf('客户') !== -1 && a.length === 1) {
        this.form.CompanyType = 1
      }
      if (a.indexOf('供应商') !== -1 && a.length === 1) {
        this.form.CompanyType = 2
      }
      if (a.length === 0) {
        this.form.CompanyType = 0
      }
    },
    selects(a) {
      this.selectsStr = ''
      this.selectsArr = []
      if (a.length === 0) return this.selectsStr = ''
      a.map(item => {
        this.selectsStr += item.IDX + ','
        this.selectsArr.push(item.IDX)
      })
      console.log(this.selectsStr)
    },
    ImgsListSeclt(a) {
      this.ImgsListSecltStr = ''
      if (a.length === 0) return this.ImgsListSecltStr = ''
      a.map(item => {
        this.ImgsListSecltStr += item.IDX + ','
      })
      console.log(this.ImgsListSecltStr)
    }
  },
  mounted() {
    // this.getTableDataAsync()
    // this.columnDrop()
    if (this.$route.params.IDX) {
      this.active = this.$route.params.active
      this.IDX = this.$route.params.IDX
      this.edit({
        IDX: 0
      }, this.IDX)
    }
    if (this.$route.params.from === 'myApproval') {
      // this.active = 1
      this.$router.push({
        name: 'NewCompy'
      })
      this.edit(this.$route.params.view_item)
    }
    if (this.$route.params.from === 'Guidepage' && this.$route.params.type === '发起首营审批') {
      this.start()
    }
    // this.getUserInfo()
    // this.$nextTick(() => {
    //   // this.rowDrop()
    //   this.tableInit()
    // })
  },
  methods: {
    // 监听选项
    tabClick(a) {
      if (a.name === 'third') {
        this.$nextTick(() => {
          this.content_view_chat = document.getElementById('content_view')
          this.content_view_chat.scrollTop = this.content_view_chat.scrollHeight
        })
      }
    },
    // 审批流程
    GetApprovalRecordInfo(RecordID) {
      this.$api.GetApprovalRecordInfo({
        RecordID
      }).then(res => {
        console.log('dasdaadsd', res)
        this.RecordInfo = res.Message
      }).catch(err => console.log(err))
    },
    // 审批
    viewShenP(row) {
      console.log(row)
      if (row.Status === 0) return
      this.formJson = JSON.parse(row.FormJson)
      this.dataJson = JSON.parse(row.DataJson)
      this.view_item = row
      console.log(this.formJson, this.dataJson)
      this.showElement = true

      // this.content_view_chat.scrollTop = this.content_view_chat.offsetHeight
      this.sendMsgForm.RecordID = row.RecordID
      this.GetApprovalRecordList(row.RecordID)
      this.GetApprovalLeaveMessageList(row.RecordID)
      this.GetApprovalRecordInfo(row.RecordID)
      this.GetApprovalUserList(row.RecordID)
      this.GetCertificateType()
      this.GetApprovalRecordEditList(row.RecordID)
    },
    // 获取审批日志
    GetApprovalRecordList(ApprovalRecordID) {
      this.$api.GetApprovalRecordList({
        ApprovalRecordID
      }).then(res => {
        console.log(res)
        this.RecordList = res.Message
      })
    },
    // 查询修改日志
    GetApprovalRecordEditList(RecordID) {
      this.$api.GetApprovalRecordEditList({
        RecordID
      }).then(res => {
        console.log(res)
        this.RecordEditList = res.Message
      })
    },
    // @人列表
    GetApprovalUserList(RecordID) {
      this.$api.GetApprovalUserList({
        RecordID
      }).then(res => {
        console.log(res)
        this.UserList = res.Message
      })
    },
    // 选择@人
    select_user(row) {
      console.log(row) // UserID
      this.sendMsgForm.AtUserID += `${row.UserID},`
      this.input_value += `@${row.NickName}`
    },
    // 发送信息
    sendMsg(isFile) {
      if (!isFile) {
        if (!this.input_value) return this.$message.warning('发送内容不能为空')
      }

      // eslint-disable-next-line no-unused-vars
      // let op = document.getElementsByClassName('op')
      if (this.base64) {
        this.sendMsgForm.MessageType = 0
        this.sendMsgForm.Message = this.base64
      } else {
        this.sendMsgForm.MessageType = 1
        this.sendMsgForm.Message = this.input_value
      }
      console.log('msg', this.sendMsgForm)
      this.input_value = ''
      this.$api.SaveApprovalLeaveMessage(this.sendMsgForm).then(res => {
        console.log(res)
        this.sendMsgForm.AtUserID = ''
        this.base64 = null
        if (res.RetCode === '0') {
          this.ChatList.MessageList.push(res.Message)
          this.$nextTick(() => {
            this.content_view_chat.scrollTop = this.content_view_chat.scrollHeight
          })
          return this.$message.success('留言成功')
        }
      })
    },
    // 下载证件
    downCampZip() {
      this.$api.DownloadFirstCampZip({
        JsonStr: this.RecordInfo.FileJsonStr
      }).then(res => {
        if (res.RetCode === '0' && res.Message) {
          return this.$minCommon.downloadFile(res.Message, '.zip')
        }
        return this.$message.error('请求失败:' + res.RetMsg)
      }).catch(() => {
        return this.$message.error('请求失败:' + res.RetMsg)
      })
    },
    // 导出审批日志
    DownApprovalRecordList() {
      this.$api.DownApprovalRecordList({
        IDX: this.view_item.IDX
      }).then(res => {
        if (res.RetCode === '0') {
          this.$minCommon.downloadFile(res.Message)
          return this.$message.success('导出成功')
        }
      })
    },
    // 留言记录
    GetApprovalLeaveMessageList(RecordID) {
      this.$api.GetApprovalLeaveMessageList({
        RecordID
      }).then(res => {
        console.log(res)
        this.ChatList = res.Message
      })
    },
    // 重新发起
    restart() {
      this.showElement = false
      this.edit(this.view_item)

      // this.$message.info('正在开发中')
    },
    // 催办
    cuiban() {
      this.isAppLa = true
      this.$api.ApprovalUrging({
        IDX: this.view_item.RecordID
      }).then(res => {
        console.log(res)
        this.isAppLa = false
        if (res.RetCode === '0') {
          return this.$message.success('催办成功')
        }
        return this.$message.error('催办失败')
      })
      // this.$message.info('正在开发中')
    },
    showImage(src) {
      console.log(src)
      this.MaterialFileList = []
      this.showViewer = true
      this.MaterialFileList.push(src)
    },
    // 测试change
    testChange(filterObj) {
      console.log(filterObj)
      let Status = ''
      this.currentPage = 1
      console.log(filterObj.StatusStirng)
      filterObj.StatusStirng.forEach(item => {
        Status += `${item},`
      })
      this.searchWords.Status = Status
      this.getTableDataAsync()
    },
    selectFile(e) {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    getFile(e) {
      const inputFile = this.$refs.filElem.files[0]
      console.log('getFilePath', inputFile)
      if (inputFile.type && inputFile.size) {
        var reader = new FileReader()
        //   const url = ''
        reader.readAsDataURL(inputFile)
        this.sendMsgForm.FileExtName = '.' + inputFile.type.split('/')[1]
        // this.MessageType = 0
        reader.onload = (e) => {
          // this.sendMsgForm.Message  =
          this.base64 = ''
          this.base64 = e.target.result
          console.log(e.target.result) // base64
          // this.isPhoto = `☮${inputFile.name}☮`
          // this.input_value += this.isPhoto
          this.sendMsg(true)
        }
      }
    },
    // 展示图片预览
    showPic() {
      this.MaterialFileList = []
      this.showViewer = true
      this.tableData1.forEach(item => {
        this.MaterialFileList.push(`${this.imgsUrl}${item.PhotoUrl} `)
      })
      // this.MaterialFileList.push(src)
    },
    // 证照上传成功的保存回调
    saveInfo(info) {
      console.log(info)
      const obj = {
        IDX: 0,
        FirstCampCompanyID: info.firstCampCompanyID ? info.firstCampCompanyID : 0,
        CertificateCode: info.form.CertificateCode,
        TypeID: info.form.Code,
        PhotoUrl: '',
        ValidTo: info.form.ValidTo,
        IsLongRange: info.form.IsLongRange ? 1 : 0,
        CertificateName: info.form.CertificateName,
        CompanyName: info.form.CompanyName,
        Remark: info.form.Remark,
        Address: info.form.Address,
        TypeString: info.form.CertificateName,
        ValidToString: '',
        Status: 0
      }
      // 先获取URL
      this.$api.SaveFirstCampCompanyCertificate({
        Base64String: info.base64,
        FileExtName: info.form.FileExtName
      }).then(res => {
        console.log(res.Message.Url)
        obj.PhotoUrl = res.Message.Url
        obj.IDX = res.IDX
        this.form.CompanyCertList.push(obj)
        // 预览图片 将图片静态展现在列表里
        this.tableData1 = this.form.CompanyCertList
        console.log('dsaasddassad', this.tableData1)
        // TypeString   ValidToString
        this.tableData1.map(item => {
          item.ValidToString = this.$minCommon.formatDate(new Date(item.ValidTo), 'yyyy-MM-dd')
        })
        this.isStaticPic = true
        console.log('asddsadsa', this.form.CompanyCertList)
      })
    },
    // 发起审核
    LaunchApproval1() {
      // 发起审核之前先保存 wait
      console.log('this.params', this.$route.params)
      console.log(this.checkList)
      if (this.checkList.length === 0) {
        this.checkListisSHOW = true
      }
      console.log(this.checkList)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.checkList.length === 0) return
          this.btnLoadiung = true
          this.form.IDX = this.IDX || ''
          if (this.firstCampCompanyID) {
            this.form.IDX = this.firstCampCompanyID
          }
          if (this.form.TaxRate === '') {
            this.form.TaxRate = -1
          }
          if (this.$route.params.TYPE === '1') {
            // this.form.SourceType = 2 // 客商
            this.$api.SaveCompanySupplier(this.form).then(res => {
              console.log('保存客商', res)
              this.btnLoadiung = false
              this.checkListisSHOW = false
              this.firstCampCompanyID = res.Message // 企业ID IDX
              if (res.RetCode === '0') {
                this.$api.GetFirstCampCompanyInfo({
                  IDX: res.Message
                }).then(res1 => {
                  this.info = res1.Message
                  this.info_temp = res1.Message
                })
                this.$api.GetCompanySupplierCertList({
                  SupplierID: this.info.SupplierID
                }).then(res => {
                  this.tableData1 = res.Message
                })
                this.selectedOptions = []
                this.selectedOptions1 = []
                return
              }
              this.$message.error(res.RetMsg)
            })
            return this.btnLoadiung = false
          }
          // this.form.SourceType = 1 // 首营
          this.form.OperType = 2 // 发起审核
          if (Array.isArray(this.form.CompanyCertList)) {
            if (this.form.CompanyCertList.length <= 0) {
              this.form.CompanyCertList = null
            } else {
              this.form.CompanyCertList = JSON.stringify(this.form.CompanyCertList)
            }
          } else {
            this.form.CompanyCertList = null
          }

          this.$api.SaveFirstCampCompanyInfo(this.form).then(res => {
            this.checkListisSHOW = false
            this.btnLoadiung = false
            this.firstCampCompanyID = res.Message // 企业ID IDX
            if (res.RetCode === '0') {
              this.$message.success('发起审核成功')
              setTimeout(() => {
                // this.active = 0
                this.$router.push({
                  name: 'Merchants1',
                  params: { isCache: 6 }
                })
                // return this.handleCurrentChange(1)
              }, 1000)
              return
            }
            this.$message.error(res.RetMsg)
            return this.btnLoadiung = false
          })
          this.selectedOptions = []
          this.selectedOptions1 = []
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    selectAll1(e) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(e)
      console.log(this.ImgsListSeclt)
    },
    select1(row) {
      this.ImgsListSecltStr = ''
      this.ImgsListSeclt = []
      this.ImgsListSeclt = this.ImgsListSeclt.concat(row)

      console.log(this.ImgsListSeclt)
    },
    GetCertificateType() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },
    // 查看
    view_m(row) {
      console.log(row)
      // this.$router.push({
      //   path: '/merchants/detail'
      // })
    },
    // 获取当前信息
    getUserInfo() {
      $api.getUserInfo().then(res => {
        console.log('dsaasdasd', res)
        this.userInfo = res.Message
        // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
        this.form.ApplyDepartmentID = res.Message.PermissionGroupID * 1 ? res.Message.PermissionGroupID * 1 : ''
        const _ApplyDepartmentID = res.Message.PermissionGroupID * 1 ? res.Message.PermissionGroupID * 1 : -1
        this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
          this.ApplyUserGroup = response.Message
        })
        console.log('this.form.ApplyDepartmentID', this.form.ApplyDepartmentID)
        this.form.ApplyUserID = res.Message.UserID ? res.Message.UserID : ''

        this.form.FillFormUserID = res.Message.UserID ? res.Message.UserID : ''
        console.log(' this.form.FillFormUserID', this.form.FillFormUserID)
      })
    },
    CompanyTypeformatter(row, column) {
      return row.CompanyType === 1 ? '客户' : row.CompanyType === 2 ? '供应商' : '客户/供应商'
    },
    handleChange(value) {
      this.form.Province = CodeToText[value[0]]
      this.form.City = CodeToText[value[1]]
      this.form.Area = CodeToText[value[2]]
    },
    downXls() {
      // console.log("dasdad", this.tableListJson[ this.$refs.FieldManagement.name ])
      this.searchWords.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.searchWords.SelectIDList = this.selectsArr
      // SelectIDList
      this.$refs.ExportLoading.open()
      this.$api.ExportFirstCampCompanyInfoByUserField(this.searchWords).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('客商管理')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
      })
    },
    handleChange1(value) {
      this.form.BranchBankProvince = CodeToText[value[0]]
      this.form.BranchBankCity = CodeToText[value[1]]
      this.form.BranchBankArea = CodeToText[value[2]]
    },
    // 首营下载
    downItem(row) {
      console.log(row)
      if (row.FileJsonStr === null) return this.$message.warning('无数据可下载')
      this.$api.DownloadFirstCampZip({
        JsonStr: row.FileJsonStr
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.zip')
      })
    },
    // 上传按钮   限制图片大小
    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.warning('上传文件大小不能超过 5MB!')
        return false
      }
      this.fileinfo = file

      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = file.url
        console.log('this.option.img ', this.option.img)
      })
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        console.log(data)
      })
      // 获取截图的base64 数据
      this.$refs.cropper.getCropData((data) => {
        console.log(data)
        this.option.view_image = data
      })
    },
    success(response, file, fileList) {
      console.log(response, file, fileList)
    },
    uoLoad() {
      this.dialogTableVisible1 = true
      const IDX = this.info.IDX
      this.info = {
        IsLongRange: false,
        CertificateName: '',
        CompanyName: this.info.CompanyName
      }
      // this.info.IsLongRange = false
      this.IDX = ''
      this.info.IDX = IDX
      this.GetCertificateType()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      const response = await this.$api.FirstCampCompanyList(this.searchWords)
      const {
        Message,
        Total
      } = response
      this.tableData = Message
      this.tableTotalSize = Total
      return response
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getInfo(index) {
      // 状态 1：编辑中 2：审核中 3：已通过 -1：已驳回
      switch (index) {
        case 2:
          return {
            info: '已审核', type: 'success'
          }
        case 3:
          return {
            info: '已驳回', type: 'danger'
          }
        case 1:
          return {
            info: '审核中', type: 'primary'
          }
        case 0:
          return {
            info: '编辑中', type: 'info'
          }
        default:
          return {
            info: '已禁用', type: 'warning'
          }
      }
    },
    isBumen(e) {
      console.log(e)
      this.form.ApplyUserID = ''
      // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
      const _ApplyDepartmentID = (this.form.ApplyDepartmentID === null || this.form.ApplyDepartmentID.length < 1) ? -1 : this.form.ApplyDepartmentID
      this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
        this.ApplyUserGroup = response.Message
      })
    },
    // 加载更多
    loadmore() {
      // console.log(e)
      // this.ApplyUserPage++
      this.ApplyUserPage++
      // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
      const _ApplyDepartmentID = (this.form.ApplyDepartmentID === null || this.form.ApplyDepartmentID.length < 1) ? -1 : this.form.ApplyDepartmentID
      this.GetApplyUserInfo(_ApplyDepartmentID, this.ApplyUserPage, this.ApplyUserPageSize, '').then(response => {
        this.ApplyUserGroup = this.ApplyUserGroup.concat(response.Message)
        console.log(response)
      })
    },
    toStep(n) {
      console.log(n)
      this.active = n
    },
    isC(e) {
      console.log(e)
      console.log(this.form)
    },
    back() {
      // this.$router.push('/Merchants')
      // this.active = 0
      this.$router.push({
        name: 'Merchants1'
      })
      this.getTableDataAsync()
    },
    getTaxInfo(_tax) {
      switch (_tax) {
        case 0.17:
          return '17%'
        case 0.16:
          return '16%'
        case 0.13:
          return '13%'
        case 0.11:
          return '11%'
        case 0.1:
          return '10%'
        case 0.06:
          return '6%'
        case 0.03:
          return '3%'
        case 0.01:
          return '1%'
        case 0:
          return '0%'
        default: return null
      }
    },
    save1() {
      if (this.checkList.length === 0) {
        this.checkListisSHOW = true
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.checkList.length === 0) return
          this.btnLoadiung1 = true
          this.form.IDX = this.IDX || ''
          if (this.firstCampCompanyID) {
            this.form.IDX = this.firstCampCompanyID
          }
          if (this.form.TaxRate === '') {
            this.form.TaxRate = -1
          }
          if (this.$route.params.TYPE === '1') {
            this.$api.SaveCompanySupplier(this.form).then(res => {
              console.log('保存客商', res)
              this.btnLoadiung1 = false
              this.checkListisSHOW = false
              this.firstCampCompanyID = res.Message // 企业ID IDX
              if (res.RetCode === '0') {
                this.handleCurrentChange(1)
                this.selectedOptions = []
                this.selectedOptions1 = []
                return
              }
              this.$message.error(res.RetMsg)
            })
            return this.btnLoadiung1 = false
          }
          this.form.OperType = 1 // 保存草稿
          if (Array.isArray(this.form.CompanyCertList)) {
            if (this.form.CompanyCertList.length <= 0) {
              this.form.CompanyCertList = null
            } else {
              this.form.CompanyCertList = JSON.stringify(this.form.CompanyCertList)
            }
          } else {
            this.form.CompanyCertList = null
          }
          this.$api.SaveFirstCampCompanyInfo(this.form).then(res => {
            this.checkListisSHOW = false
            this.btnLoadiung1 = false
            this.firstCampCompanyID = res.Message // 企业ID IDX
            if (res.RetCode === '0') {
              // this.active = 0
              this.$message.success('保存成功')
              setTimeout(() => {
                this.$router.push({
                  name: 'Merchants1',
                  params: { isCache: 6 }
                })
              }, 1000)

              // return this.handleCurrentChange(1)
            }
            this.$message.error(res.RetMsg)
            return this.btnLoadiung1 = false
          })
          this.selectedOptions = []
          this.selectedOptions1 = []
        }
      })
    },
    setItem() {
      console.log(this.IDX, this.firstCampCompanyID)
      // this.active = 1
      this.$router.push({
        name: 'NewCompy'
      })
      this.form.IDX = this.firstCampCompanyID
    },
    delImgs(row, isAll) {
      console.log(row)
      if (!isAll && this.ImgsListSecltStr === '') return this.$message.warning('请选择你要操作的数据')
      this.$confirm('确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteFirstCampCompanyCert({
          IDXList: isAll ? row.IDX + '' : this.ImgsListSecltStr
        }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.$api.GetFirstCampCompanyCertificateList({
              firstCampCompanyID: this.firstCampCompanyID
            }).then(res => {
              this.tableData1 = res.Message
            })
          }
        })
      })
    },
    // 设置修改默认的显示
    setType(type) {
      if (type === 4) {
        return this.checkList = ['客户', '供应商']
      }
      if (type === 1) {
        return this.checkList = ['客户']
      }
      if (type === 2) {
        return this.checkList = ['供应商']
      }
    },
    edit(row, idx) {
      console.log(row)
      // this.active = 1
      this.form.CompanyCertList = []
      this.IDX = row.IDX ? row.IDX : idx
      console.log(' this.IDX', this.IDX)
      this.$api.GetFirstCampCompanyInfo({
        IDX: this.IDX
      }).then(res => {
        this.form = res.Message
        this.view_item = res.Message
        this.$router.push({
          name: 'newCompy',
          params: {
            IDX: this.IDX,
            form: res.Message
          }
        })
        // phalange 20210607 创建首营时 首营id 为表单idx
        this.firstCampCompanyID = res.Message.IDX
        // this.info = res.Message
        // this.info_temp = res.Message
        // 设置供应商或客户
        this.setType(this.form.CompanyType)
        this.selectedOptions = [TextToCode[res.Message.Province].code, TextToCode[res.Message.Province][res.Message.City].code, TextToCode[res.Message.Province][res.Message.City][res.Message.Area].code]
        if (res.Message.BranchBankProvince) {
          this.selectedOptions1 = [TextToCode[res.Message.BranchBankProvince].code, TextToCode[res.Message.BranchBankProvince][res.Message.BranchBankCity].code, TextToCode[res.Message.BranchBankProvince][res.Message.BranchBankCity][res.Message.BranchBankArea].code]
        }
        this.$api.PermissionGroupInfo().then(res => {
          console.log('申请部门', res)
          this.PermissionGroup = res.Message
          this.getUserInfo()
          this.$api.GetFirstCampCompanyCertificateList({
            firstCampCompanyID: this.IDX
          }).then(res_ => {
            console.log(res)
            this.form.CompanyCertList = res.Message
            this.tableData1 = res_.Message
          })
        })
      })
    },
    select(row) {
      this.selects = row
    },
    del(row, isAll) {
      console.log(row, isAll)
      if (!isAll && this.selectsStr === '') return this.$message.warning('请选择你要操作的数据')
      this.$confirm('确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteFirstCampCompany({
          IDXList: isAll ? row.IDX : this.selectsStr
        }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.refreshCurrentChange()
          }
        })
      }).catch(() => {

      })
    },
    selectAll(e) {
      this.selects = e
    },
    // 发起企业首营
    start() {
      // this.active = 1
      this.$router.push({
        name: 'newCompy'
      })
      this.form = {
        IDX: 0,
        ApplyDate: this.$minCommon.formatDate(new Date(), 'yyyy/MM/dd'),
        // phalange 20210608  this.form.ApplyDepartmentID 没有则传入 -1 不能传入 ''
        ApplyDepartmentID: null,
        ApplyUserID: '',
        CompanyType: 1,
        CompanyName: '',
        CreditCode: '',
        CompanyAbbreviation: '',
        CompanyNo: '',
        ContactName: '',
        ContactTel: '',
        Fax: '',
        Email: '',
        CorporationName: '',
        CorporationTel: '',
        AuthorizedPerson: '',
        Province: '',
        City: '',
        Area: '',
        Street: '',
        Bank: '',
        BranchBankProvince: '',
        BranchBankCity: '',
        BranchBankArea: '',
        BranchBank: '',
        PayType: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        Remark: '',
        CompanyCertList: [] // 暂存的证照
      }
      // phalange 20210607 创建首营时 首营 firstCampCompanyID IDX 为空
      this.firstCampCompanyID = null
      this.IDX = null
      this.$api.PermissionGroupInfo().then(res => {
        console.log('申请部门', res)
        this.PermissionGroup = res.Message
        this.getUserInfo()
      })
    },
    // 申请部门
    // update 20210608 phalange 新增 name
    async GetApplyUserInfo(permissionGroupInfoId, pageIndex, pageSize, name) {
      return await this.$api.GetApplyUserInfo({
        permissionGroupInfoId,
        pageIndex,
        pageSize,
        name
      })
    },
    cancle() {
      this.active = 0
      this.selectedOptions = []
      this.selectedOptions1 = []
    },
    Adsearch() {
      this.isShow = false
      this.isShow = true
      console.log(this.$store.state)
    },
    getTypeNum(arr) {
      if (arr.indexOf('客户') !== -1 && arr.indexOf('供应商') !== -1) {
        return 4
      }
      if (arr.indexOf('客户') !== -1 && arr.length === 1) {
        return 1
      }
      if (arr.indexOf('供应商') !== -1 && arr.length === 1) {
        return 2
      }
      if (arr.length === 0) {
        return 0
      }
    },
    async searchAd() {
      this.$store.dispatch('index/setisLodingComponent', true)
      for (const name in this.formSearch) {
        this.searchWords[name] = this.formSearch[name]
      }
      console.log(this.searchWords)
      this.searchWords.ApplyDateBegin = this.searchWords.ApplyDate[0]
      this.searchWords.ApplyDateEnd = this.searchWords.ApplyDate[1]
      this.searchWords.CompanyType = this.getTypeNum(this.searchWords.CompanyType)
      console.log(this.searchWords)
      this.setKeysData(this.searchWords, this.adSearchForm) // 展示搜索条件
      const response = await this.$api.FirstCampCompanyList(this.searchWords)
      // console.log(response)
      const {
        Message,
        Total
      } = response
      this.tableData = Message
      this.tableTotalSize = Total
      this.$store.dispatch('index/setisLodingComponent', false)
      // for (const name in this.searchWords) {
      //   this.searchWords[name] = ''
      // }
      // for (const name in this.formSearch) {
      //   this.searchWords[name] = ''
      // }
      this.isShow = false
    },
    editImgs(row) {
      this.IDX = row.IDX + ''
      this.dialogTableVisible1 = true
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        this.types = res.Message
      })
      this.$api.GetFirstCampCertificateInfo({
        IDX: this.IDX
      }).then(res => {
        console.log(res)
        this.info = res.Message
        this.info.IsLongRange === 1 ? this.info.IsLongRange = true : this.info.IsLongRange = false
        this.info.IsLongRange ? this.info.ValidTo = '' : ''
        this.$refs.Uploads.option.img = `${this.b2bstaticUrl}${this.info.PhotoUrl}`
      })
    },
    downLoadImgs(row) {
      console.log(row.PhotoUrl.split('.')[1])
      this.$api.GetFirstCampFiles({
        FileURL: this.b2bstaticUrl + row.PhotoUrl
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.' + row.PhotoUrl.split('.')[1])
      })
    },
    cancels(e) {
      this.dialogTableVisible1 = e
      if (this.firstCampCompanyID) {
        this.$api.GetFirstCampCompanyCertificateList({
          firstCampCompanyID: this.firstCampCompanyID
        }).then(res => {
          console.log(res)
          this.tableData1 = res.Message
        })
      }
    }
  }
}

export default Index
